<template>
  <div class="page-header">

      <div class="header_top">
            <el-row style="display: flex;align-items: center">
              <el-col :span="18" @click="imgClick()">
                <el-image style="width: 180px" :src="require('@@/assets/logo.png')"></el-image>
              </el-col>
              <el-col :span="2" >
                <el-button type="warning" style="background-color: #5E46D4;border: 1px solid #5E46D4" @click="showdialog('/register')">注册</el-button>
              </el-col>
              <el-col :span="3" class="headlerrightbtn ">
                <div class="head_dv">
                  <p>400-1166-160</p>
                  <p style="font-size: 15px;">(工作时间：8:30-18:00)</p>
                </div>
              </el-col>
            </el-row>
      </div>
      <div class="header_router">
        <div class="titleContainer flexRowClass">
          <div v-for="(item, index) in menuItems" :key="index" :class="current === item.location ? 'activeClass' : ''" class="titleClass" @click="handleSelect(item.location)">{{ item.label }}</div>
        </div>
      </div>
    <el-dialog :title="topath == '/login' ? '请选择登录方式' : '请选择注册方式'" :visible.sync="dialogVisible" width="500px">
      <div style="text-align: center">
        <el-button @click="selectLogo(2)">投标人注册</el-button>
        <el-button @click="selectLogo(3)">代理机构注册</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      showbox: true,
      dialogVisible: false,
      usertype: "0",
      topath: "",
      current: "/home",
      islogin: false,//是否已登陆
      menuItems: [
        {
          label: "首页",
          location: "/home",
        },
        {
          label: "交易信息",
          location: "/about",
        },
        {
          label: "帮助中心",
          location: "/help",
        },
        {
          label: "下载中心",
          location: "/download",
        },
        {
          label: "关于我们",
          location: "/ourself",
        },
      ],
    };
  },
  created() {
    // if (Cookies.get('Admin-Token')) this.islogin = true;
    let menu = sessionStorage.getItem("menu");
    this.current = menu ? (menu == "/" ? "/home" : menu) : "/home";
  },
  mounted() {
    this.$bus.$on("changeMainNav", (data) => {
      this.current = data
    });
  },
  methods: {
    imgClick(){
      this.$router.push({path: "/home",});
    },
    gotoCA(){
      window.open("https://hebcaonline.hebca.com/Hebca/foreground/eseal/applyCertAndAutoSignPage.action?projectType=gzzcdzzb&cztype=1&certType=null")
    },
    // 顶部导航跳转
    handleSelect(path) {
      this.toPage(path);
    },
    showdialog(path) {
      this.dialogVisible = true;
      this.topath = path;
    },
    // 页面跳转
    toPage(path, val) {
      if (path == '/guarantee') window.open('guarantee/')
      if (path == '/bidEvaluationOffice') window.open('/bidEvaluationOffice/')
      this.$router.push({path: path, query: {type: val}});
    },
    selectLogo(val) {
      if (val == 4 && this.topath == "/login") {
        window.location.href = "http://192.168.1.10:80/login2"
      } else {
        this.toPage(this.topath, val);
      }

    },
  },
  watch: {
    '$route': {
      immediate: true,
      handler(n) {
        if (n.path.indexOf('about') != -1) {
          this.$nextTick(() => this.current = '/about')
        } else {
          this.$nextTick(() => this.current = n.path)
        }
      }
    }
  }
};
</script>
<style scoped lang="less">
.page-header{
}
.header_top{
  margin: 0 auto;
  width: 1400px;
  padding: 10px;
}
.header_router{
  background-color: #5E46D4;
  height: 64px;
  width: 100%;
}
.titleContainer {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;

}
.titleClass {
  color: #fff;
  font-size: 18px;
  width: 240px;
  height: 64px;
  line-height: 64px;
  cursor: pointer;
  text-align: center;
}
.activeClass {
  background: #4B36AF ;

}
//.header_router_menu{
//  margin: 0 auto;
//  border: 1px solid #000;
//  width: 1400px;
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//}
//.headler {
  //height: 61px;
  //border: 1px solid #000;
  //
  ///deep/ .el-menu-item {
  //  padding: 0 3px;
  //  //height: 46px;
  //  margin: 0 17px;
  //}

  ///deep/ .el-menu.el-menu--horizontal {
  //  border-bottom: none;
  //}

  //& > div {
  //  height: inherit;
  //  align-items: center;
  //}
  //
  .headlerrightbtn {
    button {
      //height: 30px;
      //line-height: 30px;
      padding: 0 20px;
    }
  }
//}

//.headlerlogotitle {
//  //height: 61px;
//  font-size: 18px;
//  color: #f18c1d;
//  font-weight: bold;
//  padding-right: 26px;
//
//  span {
//    margin: 0 10px;
//  }
//}
//
//.registerClass {
//  background-color: #F18C1D;
//  border: none;
//  width: 80px;
//  height: 40px;
//  color: #FFFFFF;
//  font-size: 20px;
//  border-radius: 4px;
//  padding: 4px;
//  line-height: 30px;
//}
//
//::v-deep .el-menu--horizontal > .el-menu-item.is-active {
//  border: none;
//}
.head_dv{
  width: 210px;
  //height: 61px;
  float: right;
  text-align: right;
  //padding-top: 5px;
}
.head_dv p {
  font-size: 24px;
  color: #5E46D4;
  //line-height: 5px;
}

</style>
