import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import '@@/assets/css/styles.css'
import ElementUI from 'element-ui'
import router from "./router"
import plugins from './plugins'
Vue.use(plugins)
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
Vue.use(ElementUI)
router.beforeEach((to, from, next) => {
  if (to.meta.title) {

    document.title = to.meta.title
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    // 兼容IE
    window.scrollTo(0, 0);
  }

  next();

});
export function createApp(mainComponent) {
  return new Vue({
    router,
    render: h => h(mainComponent)
  }).$mount('#app')
}
