<template>
  <div class="footer">
    <div class="header">
      <BottomText></BottomText>
    </div>
  </div>
</template>

<script>
 import BottomText from '@@/components/BottomText.vue'
 export default {
    components: { BottomText },
    data() {
      return {
        activeName: 'second'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      }
    }
  };
</script>
<style>
</style>
