import VueRouter from 'vue-router'
import Vue from 'vue'
import myApp from "@@/views/myApp.vue"
// 防止重复点击路由，导致提示避免到当前位置的冗余导航（路由冗余）解决方式
const routerRePush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
    return routerRePush.call(this, location).catch(error => error)
}
// 通过vue.use(插件)，安装插件
Vue.use(VueRouter)
// 创建路由对象
const routes = [
    {
        path: '/',
        component: myApp,
        children:[
            {
                path:'/login',
                name:'loginMain',
                component:()=>import("@@/views/LoginMain.vue")
            },
        ]
    },
    {
        path:'/PBLoginMain',
        name:'PBLoginMain',
        component:()=>import("@@/views/PBLoginMain.vue")
    },
    {
        path:'/superviseLogin',
        name:'SuperviseLogin',
        component:()=>import("@@/views/SuperviseLoginMain.vue")
    },
    {
        path:'/register',
        name:'RegisterMain',
        component:()=>import("@@/views/RegisterMain.vue"),
        meta: {
            title: '注册-招采通'
        }
    },
    {
        path:'/marketRegister',
        name:'marketRegister',
        component:()=>import("@@/views/marketRegister.vue")
    },

    {path:'*',redirect:'/login'},

]
const router = new VueRouter({
    // 配置URL和组价直接的映射关系
    routes,
    // history模式
    mode: 'history'
})
// 将router对象传入到vue实例中
export default router
