<template>
  <div class="rightmenu flex_content">
    <!-- <div class="gongzong" v-if="action==3">
        <el-image src="" ></el-image>
        <p>公众号</p>
    </div>

    <div class="phone" v-else>13883718867</div> -->


    <ul style="width: 200px;text-align: center">
      <div style="width: 100px;text-align: center;background-color:#fff;padding: 4px">
        <el-image style="width: 100px;"  :src="`${erweima ? apkCodetest : apkCode }`"></el-image>
        <div>扫码下载</div>
        <div>招采通app</div>
      </div>
      <li @mouseenter.stop="setAction(rightmenulist[0].type)" @mouseleave="setMouseleave()">
        <el-image class="imgli" :src="rightmenulist[0].img"></el-image>
        <div class="rit phone" v-if="action==2">400-1166-160</div>
      </li>
      <li @click.stop="setAction(rightmenulist[2].type)">
        <el-image class="imgli" :src="rightmenulist[2].img"></el-image>
      </li>
    </ul>
  </div>
</template>
<script>
import apkCode from '@@/assets/images/apkCode.png';
import apkCodetest from '@@/assets/images/apkCodeTest.png';
export default {
  data() {
    return {
      action: 1,
      apkCode,
      apkCodetest,
      erweima:'',
      rightmenulist: [
        {
          type: 2,
          img: require('@@/assets/images/icon-dh.png'),
          img2: require('@@/assets/images/icon-dh2.png'),
        },
        {
          type: 3,
          img: require('@@/assets/images/icon-ewm2.png'),
          img2: require('@@/assets/images/icon-ewm.png'),
        },
        {
          type: 4,
          img: require('@@/assets/images/returntop.jpg'),
          img2: require('@@/assets/images/returntop.jpg')
        }
      ],
    };
  },
  created() {
    const path = location.href
    if(path.indexOf('test.zct100.cn') > -1){
      this.erweima = true
    }else {
      this.erweima = false
    }
  },
  methods: {
    setAction(type) {
      this.action = this.action == type ? '' : type;
      if (type == 4) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
    },
    setMouseleave(){
        this.action = ''
    },
  }
};
</script>
<style lang="less" scoped>
ul, li {
  padding: 0;
  margin: 0;
}

.rightmenu {
  width: 111px;
  position: fixed;
  right: 50px;
  top: 550px;
  align-items: flex-end;
  z-index: 9999;

  li {
    position: relative;
    line-height: 30px;
  }

  /deep/ .el-image__inner {
    vertical-align: middle;
  }

  .rit {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(calc(-100% - 5px));
  }

  .gongzong {
    width: 100px;
    height: 130px;
    font-size: 12px;
    padding: 6px;
    background-color: #ffffff;
    text-align: center;
    // /deep/ .el-image{
    //     width: 60px;
    //     height: 60px;
    // }
  }

  .phone {
    margin-right: 5px;
    width: 100px;
    font-size: 12px;
    line-height: 34px;
    background-color: #e8e7e7;
    text-align: center;
    box-shadow: 0 1px 5px gray;
  }

  p {
    font-size: 20px;
  }

  ul {
    //width: 40px;
    padding: 0;

    li {
      height: 40px;
      text-align: center;
      width: 40px;
      background-color: #ffffff;
      margin: 10px auto;
      border-radius: 5px;
      //margin-top: 13px;
      line-height: 40px;
      font-size: 22px;

      /deep/ .imgli.el-image {

        width: 20px;
        // height: 16px;
      }
    }
  }

  .img {
    width: 85px;
    height: 85px;
  }
}
</style>
